import dayjs from 'dayjs'

const customParseFormat = require('dayjs/plugin/customParseFormat')
const localizedFormat = require('dayjs/plugin/localizedFormat');
const weekOfYear = require('dayjs/plugin/weekOfYear')
const weekday = require('dayjs/plugin/weekday')
const duration = require('dayjs/plugin/duration')
const relativeTime = require('dayjs/plugin/relativeTime')

dayjs.extend(customParseFormat)
dayjs.extend(localizedFormat);
dayjs.extend(weekOfYear)
dayjs.extend(weekday)
dayjs.extend(duration)
dayjs.extend(relativeTime)

require('dayjs/locale/en')
require('dayjs/locale/ru')

dayjs.locale('ru')

export default dayjs