<template>
    <fragment>
        <gravatar v-if="!user.profile_image" :class="gClass" :email="user.email" :default-img="gDefault"/>
        <img v-else :src="$cdn({
            image: user.profile_image,
            all: {
                r: size
            }
        })" :class="gClass">
    </fragment>
</template>

<script>
import Gravatar from 'vue-gravatar';
import { Fragment } from 'vue-fragment'

export default {
    name: 'ProfileAvatar',
    props: {
        user: Object,
        gclass: {
            default: ''
        },
        size: {
            type: Number,
            default: 30
        }
    },
    computed: {
        gDefault() {
            return `https://eu.ui-avatars.com/api/${this.user.name}`
        },
        gClass() {
            return this.gclass ? this.gclass : 'avatar-md rounded-circle'
        }
    }, 
    components: {
        Gravatar,
        Fragment
    }
}
</script>